import { NgModule } from '@angular/core';
import {RouterModule, type Routes, UrlMatchResult, UrlSegment} from '@angular/router';

import { OfflineComponent } from "./pages/offline/offline.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { AgentGuard } from "./shared/guards/agent-guard.service";
import { SignInComponent } from "./pages/sign-in/sign-in.component";

function uuidV4Matcher(segments: UrlSegment[]): UrlMatchResult {
    const uuidV4Pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (segments.length === 1 && uuidV4Pattern.test(segments[0].path)) {
        return { consumed: segments };
    }

    return { consumed: [] };
}

const routes: Routes = [
    {
        matcher: uuidV4Matcher,
        canActivate: [AgentGuard],
        loadChildren: () => import('./shared/component.module').then(m => m.ComponentModule)
    },
    { path: 'not-found', component: NotFoundComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'offline', component: OfflineComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}

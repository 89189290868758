<div class="box-loader">
    <div class="gradient-bg">
        <div class="gradients-container">
            <div class="g1"></div>
            <div class="g2"></div>
            <div class="g3"></div>
            <div class="g4"></div>
        </div>
    </div>
</div>

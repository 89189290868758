import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { ConfigService } from "./config.service";
import { LoginModel } from "../models/loginModel";
import { UserModel } from "../models/user";
import { HttpService } from "./http.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private http: HttpService,
        private config: ConfigService) {}

    async login(model: LoginModel) {
        const url = `${this.config.getUrl()}/auth/login`;
        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                })
                .setContent(model)
                .create<LoginModel>()
            );

            return {
                token: response.body?.token || '',
                status: response.status,
                message: response.message
            }
        } catch (e: any) {
            return {
                token: null,
                status: e.status,
                message: e.message
            }
        }
    }

    async isLogged(): Promise<boolean> {
        const token = this.config.getToken();
        if (token == null) return false;

        if (await this.validateToken(token)) {
            return true;
        } else {
            localStorage.removeItem('token');
            return false;
        }
    }

    private async validateToken(token: string): Promise<boolean> {
        const url = `${this.config.getUrl()}/auth/validate`;
        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                })
                .create<UserModel>()
            );

            if (response.body) {
               this.config.setUser(response.body);
            } else {
                this.config.setUser(<UserModel>{});
            }
            return response.status === 200;
        } catch (e: any) {
            return false;
        }
    }

    async logout() {
        const url = `${this.config.getUrl()}/auth/logout`;
        try {
            await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create()
            );

            localStorage.removeItem('token');
        } catch (e: any) {
            console.error(e);
        }
    }

    async forgotPassword (email: string) {
        const formData = new FormData();
        formData.append("email", email);

        await new Promise(resolve => setTimeout(resolve, 2000));
    }
}

<div class="box-sign-in">
    <div class="sign-in-container">
        <div class="text-container">
            <img [src]="exafyLogo" alt="Logo icon"/>
            <div class="title-container">
                <p class="welcome-description">Next Level Efficiency</p>
                <h1 class="welcome-title">The trusted company and<br> platform for AI process<br> automation</h1>
                <div class="social-media-container">
                    <p>Check Our Social Media:</p>
                    <button id="id-instagram" type="button" class="instagram-button" (click)="instagram()">
                        <img [src]="instagramImage" alt="Instagram icon">
                    </button>
                    <button id="id-linkedin" type="button" class="instagram-button" (click)="linkedin()">
                        <img [src]="linkedinImage" alt="Linkedin icon">
                    </button>
                </div>
            </div>
            <a class="site-link" href="https://www.exafysolutions.ae/" target="_blank">exafysolutions.ae</a>
        </div>
        <div class="form-container">
            <div class="form">
                <div class="login-title">
                    <p>Sign in</p>
                </div>
                <form action="" method="POST" class="login-auth-form">
                    <div class="login-form-group">
                        <label for="login-username">Username</label>
                        <input type="text" name="login-username" id="login-username" placeholder="Username"
                               [(ngModel)]="username"/>
                    </div>
                    <div class="login-form-group">
                        <label for="login-password">Password</label>
                        <input type="password" name="login-password" id="login-password" placeholder="Password"
                               [(ngModel)]="password" (keyup.enter)="login()"/>
                    </div>
                </form>
                <div class="login-footer">
                    <a id="id-forgot-password" class="forgot-label" (click)="forgotPassword()">Forgot password?</a>
                    <button id="id-login" type="button" class="login-button" (click)="login()" [disabled]="!isFormFilled()">Login</button>
                </div>
            </div>
        </div>
        <div class="green-lines-container">
            <img [src]="greenLinesImage" alt="Green lines image" />
        </div>
        <div class="blue-lines-container">
            <img [src]="blueLinesImage" alt="Blue lines image" />
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { AlertService } from "../../core/services/alert.service";
import { LoginModel } from "../../core/models/loginModel";
import { AuthService } from "../../core/services/auth.service";
import { RouterService } from "../../core/services/router.service";
import { ConfigService } from "../../core/services/config.service";
import { EventService } from "../../core/services/event.service";

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrl: './sign-in.component.scss'
})
export class SignInComponent implements OnInit {
    exafyLogo: string = "assets/icons/exafy-logo.svg"
    blueLinesImage: string = "assets/images/blue-lines.png"
    greenLinesImage: string = "assets/images/green-lines.png"
    instagramImage: string = "assets/icons/instagram.svg"
    linkedinImage: string = "assets/icons/linkedin.svg"

    username: string = '';
    password: string = '';

    constructor(
        private alert: AlertService,
        private auth: AuthService,
        private router: RouterService,
        private config: ConfigService,
        private event: EventService,
        private title: Title) {
        this.title.setTitle('Sign in');
    }

    ngOnInit(): void {
        const a = this.config.getAgentId();
        console.log(a);
        console.log('AGENT');
    }

    async login() {
        this.alert.showLoading();

        if (!this.username || !this.password) {
            return;
        }

        const loginModel: LoginModel = {
            email: this.username,
            password: this.password
        };

        const loginResponse = await this.auth.login(loginModel);

        this.alert.close();
        if (loginResponse.status == 200) {
            if (loginResponse.token) localStorage.setItem('token', loginResponse.token);
            this.router.goToLanding(this.config.getAgentId()!)
        } else if (loginResponse.status == 401) {
            this.alert.showError('Login failed!', 'Check if your username and password are correct. Please try again.');
        } else {
            this.alert.showError('Login failed!', 'An error occurred while trying to login. Please try again.');
        }
    }

    forgotPassword() {
    }

    instagram() {
        window.open('https://www.instagram.com/_exafydigitalsolutions', '_blank');
    }

    linkedin() {
        window.open('https://www.linkedin.com/company/exafy-digital-solutions/', '_blank');
    }

    isFormFilled(): boolean {
        return !(!this.username || this.username.trim() === "" || !this.password);
    }
}

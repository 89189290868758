import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, Router } from "@angular/router";

import { AlertService } from "./core/services/alert.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
    loading: boolean = false;

    @HostListener('window:resize')
    onResize(): void {
        this.setVh();
    }

    constructor(private router: Router, private alert: AlertService) {
        // Start and end loading while canActive is running
        this.router.events.subscribe(event => {
            if (event instanceof GuardsCheckStart) {
                this.loading = true;
                this.alert.showLoading();
                console.log("ResolveStart")
            }
            if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
                this.loading = false;
                this.alert.close();
                console.log("ResolveEnd")
            }
        });
    }

    private setVh(): void {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    async ngOnInit() {
        this.setVh();
    }

    ngOnDestroy(): void {
        window.removeEventListener('resize', this.onResize);
    }
}

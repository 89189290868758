import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    constructor(private router: Router) {}

    goToLostNetwork() {
        this.router.navigate(['/lost-network']);
    }

    goToLanding(id: string) {
        this.router.navigate(['/project/' + id]);
    }

    goToNotFound() {
        this.router.navigate(['/not-found']);
    }

    goToSignIn() {
        this.router.navigate(['/sign-in']);
    }
}
